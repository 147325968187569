import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm' 

const Testimonials = () => {
   
 
  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Our Company | Sell My House Fast In Sacramento, CA'}
        description={'Need to sell your house fast? We buy houses in Sacramento, CA in as little as 7 days. If youre saying I need to sell my house fast!, wed like to buy!'}
        keywords={''}
      />


<section className="py-16 section-stripe-bg bg-gray-50 ">
   <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
      <div className="w-full md:w-2/3 md:pr-4 prose">
        
      <h1>What Others Say…</h1>
<p>It’s important to us that the homeowners we work with have a great experience with us. In the end, we’re not happy if we’re not able to provide you with the solution you want. So, check out what others have said about us. Here are just a few snippets of what other people we’ve worked with have said.</p>
<p>Want to share your own experience with us? <a href="/contact-us/">Send us a note</a> to let us know what working with Sell Your Home For Cash Sacramento has been like for you.</p>
        
        
          </div>
      <div className="w-full md:w-1/3 md:pl-4">
         <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
            <div className="px-8 pt-8">
               <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
               <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses in any condition -- no commissions or fees and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
            </div>
            <div className="p-8 pt-4">
               <ContactForm stacked={true} hideLabels={true} showPlaceholders={true} />
            </div>
         </div>
      </div>
   </div>
</section>


 

    </Layout>
  );
};


export default Testimonials;

 